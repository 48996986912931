<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../assets/img/daily.png" alt="" width="28">
        <h2>屏障环境日常温湿度、压差记录表</h2>
      </div>
    </div>
    <el-tabs v-model="activeName" class="contentBody">
      <el-tab-pane label="日历" name="first">
        <div class="flex between">
          <el-select v-model="roomId" size="small" placeholder="请选择房间号" class="selectRoom">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="(item.nameNo==null?'':item.nameNo)+item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <div class="noRecord flex" v-if="Object.keys(oneDayList).length==0">
            <span class="selectTime">{{ dateV | formatDay1 }}</span>
            <span style="width: 100px;">{{ roomId == '' ? '请先选择房间号' : '暂无记录' }}</span>
          </div>
          <div class="calendarRecord" v-else v-for="monitor in oneDayList"
               :key="monitor.id">
            <span class="selectTime">{{ dateV | formatDay1 }}</span>
            <p><span>时间：</span><span>{{ new Date(monitor.createDate).toLocaleDateString() }}</span></p>
            <p><span>温度：</span><span>{{ monitor.temper }}</span></p>
            <p><span>湿度：</span><span>{{ monitor.humidity }}</span></p>
            <p><span>压差：</span><span>{{ monitor.differPressure }}</span></p>
            <p><span>记录人：</span><span>{{ monitor.createBy }}</span></p>
          </div>
        </div>
        <el-calendar v-model="dateV" class="calendar">
          <template
              slot="dateCell" slot-scope="{date,data}">
                <span class="flex" :class=" (new Date().getTime()-new Date(data.day).getTime())> 0 ? 'pastTime':''">
                  <span class="flex" :class="excpList.includes(data.day)?'error':''">
                    <span class="flex"
                          :class="!excpList.includes(data.day)&& !commonList.includes(data.day)&& (new Date().getTime()-new Date(data.day).getTime())> 0?'noDa':''">
                      {{ data.day.split('-')[2] }}
                    </span>
                  </span>
                </span>
          </template>
        </el-calendar>
      </el-tab-pane>
      <el-tab-pane label="列表" name="second">
        <div class="flex">
          <el-select v-model="valueO" size="small" filterable collapse-tags clearable
                     multiple class="selectRoom" placeholder="所有房间 " @change="search">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="(item.nameNo==null?'':item.nameNo)+item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
          <el-date-picker
              v-model="selectTime" size="small"
              type="daterange"
              class="timeRange"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="search()">
          </el-date-picker>
          <el-input type="search" clearable class="keyRecord" size="small" placeholder="请输入记录人"
                    v-model="key"></el-input>
          <el-button type="primary" @click="search" size="small" class="search">搜索</el-button>
        </div>
        <el-table
            :data="tableData"
            max-height="calc(100vh - 413px)" height="calc(100vh - 413px)"
            :header-cell-style="{'background':'#EAF3FF','color': '#09BEE9',}"
            class="record-table">
          <el-table-column
              prop="createDate"
              label="日期"
              align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.createDate">{{ scope.row.createDate | formatDay }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="room.name"
              label="房间"
              align="center"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              width="100"
              align="center"
              label="时间">
            <template slot-scope="scope">
              <span v-if="scope.row.createDate">{{ scope.row.createDate | formatHours }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="ctemper"
              align="center"
              width="180"
              label="温度(℃)">
          </el-table-column>
          <el-table-column
              prop="chumidity"
              label="湿度(%)"
              align="center">
          </el-table-column>
          <el-table-column
              prop="cdifferPressure"
              align="center"
              width="180"
              label="压差(Pa)">
          </el-table-column>
          <el-table-column
              prop="createBy"
              label="记录人"
              align="center">
          </el-table-column>
        </el-table>
        <div class="flex pagination ">
          <el-pagination
              prev-text="<"
              next-text=">"
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-size="page.size"
              layout="prev, pager, next"
              :total="page.total">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
import moment from 'moment'

moment.locale('zh-cn')
export default {
  name: "daiMonitor",
  data() {
    return {
      activeName: 'first',
      roomId: '',
      options: [],
      dateV: new Date(),
      oneDayList: [],
      excpList: [],
      commonList: [],
      page: {
        total: 1,
        pageSize: 6,
        pageNum: 1,
      },
      key: "",
      valueO: [],
      tableData: [],
      selectTime: ""
    }
  },
  mounted() {
    this.listMonitorRecord();
    this.listRoomByMType();
  },
  watch: {
    dateV: { // 监视搜索字段
      handler() {
        this.getDataFromServer();
      },
      deep: true
    },
    roomId: {
      handler() {
        this.getDataFromServer();
      },
      deep: true
    }
  },
  methods: {
    getDataFromServer() {
      //console.log(this.dateV);
      if (this.roomId == '') {
        this.$message.error("请选择房间号！");
        return;
      }
      if (this.dateV > (new Date())) {
        this.$message.error("暂无记录");
        return;
      }
      let startDate = new Date(this.dateV).getTime() - 8 * 3600 * 1000;
      //console.log(startDate);
      // 发起请求
      this.$get("/daily/ac-daily-monitor/byOneDay", {
        startDate: startDate, // 搜索条件
        roomId: this.roomId
      }).then(resp => { // 这里使用箭头函数
        this.oneDayList = resp.data.oneDayList;
        this.commonList = resp.data.commonList;
        this.excpList = resp.data.excpList;
        if (this.commonList != null) {
          for (let i = 0; i < this.commonList.length; i++) {
            this.commonList[i] = moment(this.commonList[i]).format("YYYY-MM-DD");
          }
        }
        if (this.excpList != null) {
          for (let i = 0; i < this.excpList.length; i++) {
            this.excpList[i] = moment(this.excpList[i]).format("YYYY-MM-DD");
          }
        }
      })
    },
    listRoomByMType() {
      let MType = 1;
      this.$get("/daily/ac-room/byMType", {
            mType: MType
          }
      ).then(res => {
        this.options = res.data.data;
      })
    },
    listMonitorRecord() {
      this.$get('/daily/ac-daily-monitor', {
        selectTime: this.selectTime,
        roomId: this.valueO,
        key: this.key,
        page: this.page.pageNum,
        size: this.page.pageSize
      }).then(res => {
        this.tableData = res.data.rows;
        this.page.total = res.data.total
      }).catch(() => {
        this.$message.error("暂无数据！");
      });
    },
    search() {
      this.listMonitorRecord();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.listMonitorRecord();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/daily";
</style>
